import { createApp } from "/node_modules/.vite/deps/vue.js?v=4470d800"
import App from "/src/App.vue"
import router from "/src/router/index.js"
import store from "/src/store/index.js"
import Vue3TouchEvents from "/node_modules/.vite/deps/vue3-touch-events.js?v=4470d800";

createApp(App)
    .use(store)
    .use(router)
    .use(Vue3TouchEvents)
    .mount('#app')
